.container {
    width: 50%;
    height: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.horizontal {
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttonContain {
    display: flex;
    justify-content: flex-end;
}

.failureMessage {
    color: red;
    margin-right: 5px;
}