.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blockContainer {
    text-align: center;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    font-size: xx-large;
    font-weight: bold;
    color: gray;
}

.loginButton {
    width: 100%;
    background-color: gray;
    color: white;
    font-size: x-large;
    cursor: pointer;
    border: none;
    padding: 5px;
    border-radius: 10px;
}

.loginTypeButton {
    margin: 10px;
    width: 250px;
    height: 150px;
    background-color: gray;
    color: white;
    font-size: x-large;
    cursor: pointer;
    border: none;
    border-radius: 15px;
}

.error {
    color: red;
}