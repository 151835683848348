.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thirdContainer {
    width: 33.33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.twoThirdContainer {
    width: 66.66%;
    height: 100%;
    display: inline-block;
}

.headerContain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
}

.heading {
    text-align: center;
    color: gray;
    font-weight: bolder;
}