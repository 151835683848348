.container {
    width: 100%;
    height: 100%;
}

.half {
    width: 100%;
    height: 50%;
}

.backButton {
    border: none;
    background-color: inherit;
    cursor: pointer;
    padding: 10px;
    font-size: larger;
    font-weight:500;
    color: darkslategray;
}

.arpSessionsHeaderContain {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arpSessionsBodyContain {
    width: 100%;
    height: 95%;
}