.listenButton {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: x-large;
    animation: animate 1s alternate infinite;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 0 15px rgba(0, 255, 0, .2), 0 0 0 30px rgba(0, 255, 0, .2), 0 0 0 45px rgba(0, 255, 0, .2);
    }
    50% {
        box-shadow: 0 0 0 25px rgba(0, 255, 0, .2), 0 0 0 40px rgba(0, 255, 0, .2), 0 0 0 55px rgba(0, 255, 0, .2);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 255, 0, .2), 0 0 0 50px rgba(0, 255, 0, .2), 0 0 0 65px rgba(0, 255, 0, .2);
    }
}
