.container {
    width: 100%;
    display: inline-block;
}

.header {
    text-align: center;
    color: darkslategray;
}

.displayRow {
    height: 60px;
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    color: darkslategray;
    font-weight: bolder;
    border-bottom: 1px solid darkgray;
    margin-bottom: 10px;
}

.row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.button {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: 5px;
    border-color: lightgray;
    color: darkslategray;
}

.redButton {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: 5px;
    border-color: lightgray;
    color: darkslategray;
    background-color: lightpink;
}

.greenButton {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: 5px;
    border-color: lightgray;
    color: darkslategray;
    background-color: lightgreen;
}