.table {
    width: 100%;
    height: 100%;
}

.header {
    height: 45px;
    color: darkslategray;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerSection {
    width: 33.333%;
    display: flex;
}

.filterContain {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.columnHeaderContain {
    width: 100%;
    display: flex;
    box-shadow: inset 0 0 0 1px darkgray;}

.columnHeaderEntry {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    color: darkslategray;
    cursor: pointer;
}

.rows {
    overflow: scroll;
    scrollbar-width: none; 
}

.rows::-webkit-scrollbar {
    display: none;
}

.rowContain {
    width: 100%;
    display: flex;
    box-shadow: inset 0 0 0 1px darkgray;
}

.rowEntry {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    color: darkslategray;
}