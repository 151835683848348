.container {
    width: 48%;
    height: 90%;
    background-color: lightgray;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
}

.rowContain {
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.rowContain::-webkit-scrollbar {
    display: none; /* Chrome */
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    padding: 5px;
    height: 35px;
    border-radius: 10px;
    color: gray;
    cursor: pointer;
    background-color: white;
    margin: 5px 0;
}


.row:active {
    background-color: white;
}

.searchBox {
    border-radius: 10px;
}

.searchOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}