.modalContain {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    background: rgba(155, 155, 155, .6);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.modalContents {
    padding: 25px;
    background: white;
    border-radius: 10px;
}

.container {
    width: 100%;
    height: 100%;
}

.header {
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerContain {
    display: flex;
    justify-content: center;
    align-items: center;
}