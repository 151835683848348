.button {
    margin: 0;
    padding: 5px 15px;
    height: 40px;
    font-size: 10pt;
    border-radius: 10px;
    border: 1px solid gray;
    font-weight: bold;
    color: gray;
    cursor: pointer;
}