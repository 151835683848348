.container {
    display: block;
    text-align: start;
    margin: 5px 0;
}

.header {
    font-size: large;
    font-weight: bold;
    color: gray;
}

.input {
    margin: 0;
    padding: 5px;
    height: 25px;
    width: calc(100% - 12px);
    font-size: 15pt;
    font-weight: bold;
    color: gray;
    border: 1px solid gray;
    border-radius: 10px;
    box-sizing: content-box;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.input::-webkit-scrollbar {
    display: none; /* Chrome */
}

.listItem {
    list-style-type: none; /* Removes bullets */
    padding: 0 0 0 5px; /* Remove padding */
    margin: 10px 0;
    cursor: pointer;
}