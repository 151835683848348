.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.logoutContain {
    width: 100%;
    height: 100%;
    text-align: center;
}

.header {
    color: darkslategray;
    text-align: center;
    margin: 10px 0;
}

.tabs {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 10%;
}

.tab {
    margin: 0 5px;
    border: none;
    font-size: large;
    font-weight: 800;
    cursor: pointer;
}

.tabContent {
    width: 100%;
    height: 90%;
}

.title {
    color: gray;
}

.backButton {
    border: none;
    background-color: inherit;
    cursor: pointer;
    padding: 10px;
    font-size: larger;
    color: darkslategray;
}

.dateContainer {
    cursor: pointer;
    padding: 10px;
    font-size: larger;
    color: darkslategray;
}

.pageBody {
    width: 100%;
    height: 100%;
    display: flex;
}

.pageTabs {
    width: 15%;
    background-color: darkgray;
    height: 100%;
}

.tab {
    width: auto;
    margin: 10px 5px;
    padding: 5px;
    color: darkslategray;
}

.pageTabBody {
    width: 85%;
    height: 100%;
    background-color: lightgray;
}