.pageContainer {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.headerContainer {
    width: 100vw;
    height: 10vh;
    background-color: gray;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
}

.childrenContainer {
    width: 100vw;
    height: 90vh;
}

.banner {
    position: absolute;
    bottom: 20px; /* Distance from the bottom of the screen */
    left: 50%; /* Position the banner horizontally in the center */
    transform: translateX(-50%); /* Offset the banner to center it */
    padding: 10px 20px; /* Add some padding for visual appeal */
    color: white; /* White text color */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center text inside the banner */
}

/* Define the fade-in and fade-out animations */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fadeIn {
    animation: fadeIn 1s forwards;
  }
  
  .fadeOut {
    animation: fadeOut 1s forwards;
    pointer-events: none; /* Ensure it doesn't interfere with user interactions when fading out */
  }
  
  /* Hide the component when it's fully faded out */
  .hidden {
    display: none;
  }