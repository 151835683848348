.container {
    display: block;
    text-align: start;
    margin: 5px 0;
}

.header {
    font-size: large;
    font-weight: bold;
    color: gray;
}

.input {
    margin: 0;
    padding: 5px;
    height: 25px;
    font-size: 15pt;
    font-weight: bold;
    color: gray;
    border: 1px solid gray;
    border-radius: 10px;
}