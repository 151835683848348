.container {
    margin: 20px;
    height: 80%;
    padding: 10px;
    border-radius:10px;
    background-color: lightgray;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.container::-webkit-scrollbar {
    display: none; /* Chrome */
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
}

.buttons {
    width: 250px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}